(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbOrder.factory:ItemList
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .factory('ItemList', ItemList);

  function ItemList(_) {
    function ItemListBase() {
      this.items = [];
    }

    ItemListBase.prototype.setItemListStorage = function setItemListStorage(key) {
      localStorage.setItem(key, angular.toJson(this.items));
    };

    ItemListBase.prototype.getItemListStorage = function getItemListStorage(key) {
      return angular.fromJson(localStorage.getItem(key)) || [];
    };

    ItemListBase.prototype.clearItemListStorage = function clearItemListStorage(key) {
      localStorage.removeItem(key);
    };

    ItemListBase.prototype.setItems = function setItems(items) {
      this.items = items;
    };

    ItemListBase.prototype.addItem = function addItem(item) {
      this.items.push(item);
    };

    ItemListBase.prototype.deleteItem = function deleteItem(item) {
      this.items = _.filter(this.items, function (elem) {
        return elem.id !== item.id;
      });
    };

    ItemListBase.prototype.updateItem = function updateItem(item) {
      var index = _.findIndex(this.items, {id: item.id});
      this.items[index] = item;
    };

    ItemListBase.prototype.getSize = function getSize() {
      return this.items.length;
    };

    return ItemListBase;
  }
}());
